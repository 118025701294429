export let exportMenu = [
	{
		url: "/dashboard",
		i18n: "HOME",
		icon: "uil-home-alt",
		active: false,
	},
	{
		url: "/admin/clients",
		i18n: "ADMIN",
		icon: "uil-users-alt",
		active: false,
	},
	{
		url: "/pos",
		i18n: "POSES",
		icon: "uil-shop",
		active: false,
	},
	{
		url: "/admin/debt/excel",
		i18n: "DEBTOR",
		icon: "uil-file-alt",
		active: false,
	},
	{
		url: "/products",
		i18n: "PRODUCTS",
		icon: "uil-shopping-cart",
		active: false,
	},
]